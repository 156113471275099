<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <div class="d-flex justify-content-between mb-4">
          <h4>Perfil de Produtos</h4>
        </div>

        <b-button
          @click="showCreateProfileModal"
          variant="primary"
          class="mb-2 float-right"
        >
          CRIAR PERFIL
        </b-button>

        <SearchInput
          v-model="search"
          class="float-right mr-2"
          :isLoadingSearch="isLoading"
          @enter="getProfileBySearch"
        ></SearchInput>

        <b-table
          :items="activeItemTranslate"
          :fields="fields"
          bordered
          striped
          hover
        >
          <template #cell(actions)="row">
            <b-button
              variant="primary"
              size="sm"
              class="mr-2"
              title="Editar descrição do perfil"
              @click="showUpdateProfileModal(row.item)"
            >
              <b-icon icon="pencil" />
            </b-button>

            <b-button
              variant="primary"
              size="sm"
              class="mr-2"
              title="Editar atributos do perfil"
              @click="getProductAttribute(row.item.id)"
            >
              <b-icon icon="list-ul" />
            </b-button>

            <b-button
              variant="danger"
              size="sm"
              class="mr-2"
              title="Deletar perfil"
              @click="activeProfileSwitch(row.item.id, row.item.active)"
            >
              <b-icon icon="trash" />
            </b-button>
          </template>
        </b-table>

        <b-button v-if="loadMoreButton" block @click="getMore" class="mb-5">
          <b-icon v-if="isLoading" icon="arrow-clockwise" animation="spin" />
          CARREGAR MAIS
        </b-button>

        <b-modal
          v-model="attributesModal"
          size="xl"
          hide-footer
          title="Atributos do Perfil"
        >
          <b-table
            :items="productProfileAttribute"
            :fields="attributesInputField"
          >
            <template #cell(value)="row">
              <form-input
                v-model="row.item.value"
                icon="list-ul"
                :type="row.item.typeValue === 'textValue' ? 'text' : 'number'"
              />
            </template>
            <template #cell(attribute)="row">
              <form-input
                v-model="row.item.attribute"
                icon="journal-minus"
                required
              />
            </template>
            <template #cell(typeValue)="row">
              <b-form-select
                v-model="row.item.typeValue"
                :options="typeValue"
                text-field="text"
                value-field="id"
              />
            </template>
            <template #cell(standardMeasurementUnit)="row">
              <form-input
                v-model="row.item.standardMeasurementUnit"
                icon="rulers"
              />
            </template>
            <template #cell(filter)="row">
              <b-form-select v-model="row.item.filter" :options="filter" />
            </template>
            <template #cell(actions)="row">
              <b-button
                :variant="row.item.filter ? 'success' : 'danger'"
                size="sm"
                title="Tornar filtro"
                @click="switchAttribute(row.item)"
              >
                <b-icon
                  :icon="row.item.filter ? 'funnel-fill' : 'funnel'"
                ></b-icon>
              </b-button>
              <b-button
                variant="danger"
                class="ml-1"
                size="sm"
                title="Deletar  "
                @click="deleteProfileAttribute(row.item)"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </template>
          </b-table>

          <div class="d-flex justify-content-between" style="width: 100%">
            <b-button
              class="ml-2"
              variant="primary"
              @click="createNewAttribute"
            >
              NOVO ATRIBUTO
            </b-button>
            <b-button variant="primary" @click="updateProfileAttributes">
              SALVAR
            </b-button>
          </div>
        </b-modal>

        <b-modal
          v-model="createProfileModal"
          hide-footer
          :title="
            formProfile.id ? 'Editar Perfil' : 'Criar Novo Perfil de Produtos'
          "
          size="lg"
        >
          <b-form @submit.prevent="createOrderUpdateProfile">
            <form-input
              v-model="formProfile.description"
              label="Descrição"
              placeholder="Descrição do perfil"
              icon="pencil"
              :errors="errors['description']"
            >
            </form-input>
            <div class="d-flex justify-content-end">
              <b-button type="submit" variant="primary"> SALVAR </b-button>
            </div>
          </b-form>
        </b-modal>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapMutations } from "vuex";
import http from "../../plugins/http";
import FormInput from "../../components/form/FormInput.vue";
import SearchInput from "../../components/shared/SearchInput.vue";

export default {
  components: { FormInput, SearchInput },
  data() {
    return {
      page: 2,
      search: "",
      isSearch: false,
      isLoading: false,

      loadMoreButton: true,
      createProfileModal: false,
      productProfile: [],
      productProfileAttribute: [],
      attributesModal: false,
      attributes: [],
      attributeId: 0,
      formProfile: {
        id: 0,
        description: "",
        department: null,
        catalogId: null,
        groupId: null,
      },
      typeValue: [
        { id: "numericValue", text: "Numerico" },
        { id: "textValue", text: "Texto" },
      ],
      filter: [
        { id: "true", text: "Positivo" },
        { id: "false", text: "Negativo" },
      ],
      fields: [
        {
          key: "description",
          label: "Descrição",
          sortable: true,
        },
        {
          key: "actions",
          label: "Ações",
          thStyle: { width: "180px !important" },
        },
      ],
      attributesInputField: [
        {
          key: "attribute",
          label: "Atributo",
          thStyle: { width: "90px !important" },
          sortable: true,
        },
        {
          key: "value",
          label: "Valor",
          thStyle: { width: "90px !important" },
          sortable: true,
        },
        {
          key: "typeValue",
          label: "Tipo de Dados",
          thStyle: { width: "50px !important" },
        },
        {
          key: "standardMeasurementUnit",
          label: "Unidade de Medida",
          thStyle: { width: "50px !important" },
        },
        {
          key: "actions",
          label: "Ações",
          thStyle: { width: "2em !important" },
        },
      ],
      errors: {},
    };
  },
  computed: {
    activeItemTranslate() {
      return this.productProfile.map((value) => ({
        ...value,
        active: value.active ? "Verdadeiro" : "Falso",
      }));
    },
  },
  methods: {
    ...mapMutations(["setToastedSuccess", "setToastedError", "setOverlay"]),

    async activeProfileSwitch(id) {
      await http.put(`/product/profile/active/${id}`, {
        active: false,
      });
      const index = this.productProfile.findIndex((value) => value.id === id);
      this.productProfile.splice(index, 1);
    },

    showAttributeModal() {
      this.attributesModal = true;
    },
    showCreateProfileModal() {
      this.errors = {};
      this.formProfile = {
        id: 0,
        description: "",
        department: null,
        catalogId: null,
        groupId: null,
      };
      this.createProfileModal = true;
    },
    showUpdateProfileModal(profile) {
      this.errors = {};
      this.formProfile = profile;
      this.createProfileModal = true;
    },
    switchAttribute(attribute) {
      const index = this.productProfileAttribute.indexOf(attribute);
      this.productProfileAttribute.splice(index, 1, {
        ...attribute,
        filter: !attribute.filter,
      });
    },
    async getMore() {
      this.isLoading = true;
      if (!this.isSearch) {
        const { data } = await http(`/product/profile/?page=${this.page}`);
        if (data.length < 20) {
          this.loadMoreButton = false;
        }
        this.productProfile.push(...data);
        this.page += 1;
      } else {
        await this.getMoreProfileBySearch();
      }
      this.isLoading = false;
    },
    async getProfileBySearch() {
      this.loadMoreButton = true;
      this.isLoading = true;
      const { data } = await http(
        `/product/profile/?search=${this.search}&page=1`
      );
      this.productProfile = data;
      if (data.length < 20) {
        this.loadMoreButton = false;
      }
      this.page = 2;
      this.isSearch = true;
      this.isLoading = false;
    },
    async getMoreProfileBySearch() {
      const { data } = await http(
        `/product/profile/?search=${this.search}&page=${this.page}`
      );
      if (data.length < 20) {
        this.loadMoreButton = false;
      }
      this.productProfile.push(...data);
      this.page += 1;
    },
    async productProfiles() {
      const { data } = await http(`/product/profile/?page=1`);
      this.productProfile = data;
    },
    async getProductAttribute(id) {
      this.setOverlay(true);
      const { data } = await http(`/product/profile/attribute/${id}`);
      this.productProfileAttribute = data;
      this.attributesModal = true;
      this.attributeId = id;
      this.setOverlay(false);
    },
    async createNewAttribute() {
      this.errors = {};
      this.productProfileAttribute.push({
        id: 0,
        profileId: 0,
        attribute: "",
        value: "",
        typeValue: "textValue",
        standardMeasurementUnit: "",
        filter: false,
      });
    },
    async updateProfileAttributes() {
      try {
        const isAttributeEmpty = this.productProfileAttribute.some((value) => {
          return !value.attribute;
        });
        if (isAttributeEmpty) {
          this.setToastedError({
            message: "Um ou mais campos atributo vazio(s)!",
          });
        } else {
          await http.post(
            `/product/profile/modify-attribute/${this.attributeId}`,
            {
              data: this.productProfileAttribute,
            }
          );
          this.setToastedSuccess({
            message: "Atributos Atualizados com Sucesso.",
          });
          this.attributesModal = false;
        }
      } catch (error) {
        this.setToastedError({
          message: "Algum erro Aconteceu Tente Novamente.",
        });
        this.attributesModal = false;
      }
    },
    async deleteProfileAttribute(attribute) {
      try {
        if (!attribute.id) {
          const index = this.productProfileAttribute.indexOf(attribute);

          this.productProfileAttribute.splice(index, 1);
        } else {
          await http.delete(
            `/product/profile/delete-attribute/${attribute.id}`
          );
          this.productProfileAttribute = this.productProfileAttribute.filter(
            (value) => value.id !== attribute.id
          );
          this.setToastedSuccess({
            message: "Atributo Deletado com Sucesso.",
          });
        }
      } catch (error) {
        this.setToastedError({
          message: "Não foi possivel deletar o atributo. Tente Novamente.",
        });
      }
    },

    async createOrderUpdateProfile() {
      if (this.formProfile.id) {
        await this.updateProfile();
      } else {
        await this.createProfile();
      }
    },

    async createProfile() {
      this.errors = {};
      try {
        const { data } = await http.post(`product/profile/create`, {
          description: this.formProfile.description,
        });
        this.productProfile.push(data);
        this.createProfileModal = false;
      } catch (err) {
        if (err.response.status === 406) {
          this.errors = err.response.data.error;
        } else {
          console.log(err);
        }
      }
    },

    async updateProfile() {
      this.errors = {};
      try {
        const { data } = await http.put(
          `product/profile/update/${this.formProfile.id}`,
          {
            description: this.formProfile.description,
          }
        );
        const index = this.productProfile.findIndex(
          (value) => value.id === data.id
        );
        this.productProfile.splice(index, 1, data);
        this.createProfileModal = false;
      } catch (err) {
        if (err.response.status === 406) {
          this.errors = err.response.data.error;
        } else {
          console.log(err);
        }
      }
    },
  },

  created() {
    this.productProfiles();
  },
};
</script>
